.button {
  outline: none;
  text-decoration: none;
  -webkit-appearance: none;

  &--outline {
    padding: .5rem 1rem;
    font-size: 1.6rem;
    border: .1rem solid $color--white;
    border-radius: .5rem;
    background-color: rgba($color--white, 0);
    color: $color--white;
    transition: color .15s ease-in-out,
      background-color .15s ease-in-out;

    &:hover,
    &.is-active {
      background-color: $color--white;
      color: $color--primary;
    }
  }

  &--primary {
    padding: .5rem 1rem;
    font-size: 1.6rem;
    border: none;
    border-radius: .5rem;
    background-color: $color--primary;
    color: $color--white;
    transition: color .15s ease-in-out,
      background-color .15s ease-in-out;

    &:hover,
    &.is-active {
      background-color: rgba($color--primary, .8);
    }
  }
}