.hero {
  width: 100%;
  height: $height--hero;
  position: relative;
  margin-bottom: 4rem;

  @media #{$normal} {
    height: $desktop--height--hero;
    margin-bottom: 8rem;
  }

  h1 {
    color: #fff;
  }

  .slider {
    height: 100%;
    display: block;

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      margin-top: -20px;
      z-index: 1;
      background: none;
      border: none;
      outline: none;
      color: #fff;
      font-size: 2.5rem;
      cursor: pointer;

      &.slick-prev {
        left: 1.5rem;
      }

      &.slick-next {
        right: 1.5rem;
      }
    }

    .slide {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: stretch;
      align-items: flex-end;

      padding-top: $height--nav;
      padding-bottom: 5rem;

      background-color: rgba(54, 54, 54, 1);
      background-image: url(../assets/images/hero_2.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      outline: none;

      @media #{$normal} {
        padding-top: $desktop--height--nav;
        padding-bottom: $desktop--height--nav;
      }

      &:before {
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 1;
        background-color: rgba(0, 0, 0, .3);
      }
    }
  }

  .content {
    position: relative;
    color: $color--white;
    text-align: left;
    z-index: 2;

    h1 {
      font-size: 4rem;
      margin-bottom: 0;

      @media #{$normal} {
        font-size: 6.5rem;
      }
    }

    h2 {
      font-size: 2.3rem;
      margin-bottom: 1rem;

      @media #{$normal} {
        font-size: 4rem;
      }
    }
  }
}