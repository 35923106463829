h1 {
  color: #333;
}

.node--type-landing-page,
.node--type-page {

  .field--name-body {
    margin-bottom: 4rem;

    @media #{$normal} {
      margin-bottom: 7rem;
    }

    h1,
    h2,
    h3 {
      color: #333;
    }

    h3 {
      margin: 0;
    }
  }
}

// .node--type-landing-page {
//   .field--name-body {
//     text-align: center;
//   }
// }