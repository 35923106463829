// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

@mixin font--primary($weight: 400, $style: normal) {
  font-family: $font--primary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--secondary($weight: 300, $style: normal) {
  font-family: $font--secondary;
  font-weight: $weight;
  font-style: $style;
}

@mixin text-large($type: 'default') {
  @if ($type == 'include-text-style') {
    @include font--primary();
    @include font-size($font-size--body);
    line-height: $line-height--body;
    color: $color--text;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--body--large);
    line-height: $desktop--line-height--body--large;
  }
}

@mixin text-small($type: 'default') {
  @if ($type == 'include-text-style') {
    @include font--primary();
    @include font-size($font-size--body);
    line-height: $line-height--body;
    color: $color--text;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--body--small);
    line-height: $desktop--line-height--body--small;
  }
}

@mixin font-size($size) {
  font-size: #{$size}px;
  font-size: #{$size / 10}rem;
}

// -----------------------------------------------------------------------------
//
// Spacing
//
// -----------------------------------------------------------------------------

@mixin padding($size: 1) {
  @include spacing('padding', $size);
}

@mixin margin($size: 1) {
  @include spacing('margin', $size);
}

@mixin spacing($type, $size: 1) {
  #{$type}: map-get($spacings, $size);
  @media #{$normal} {
    #{$type}: map-get($desktop--spacings, $size);
  }
}

// -----------------------------------------------------------------------------
//
// Other
//
// -----------------------------------------------------------------------------

@mixin link-stretch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: transparent;
  font-size: 0;
  opacity: 0;
  text-indent: -999.9rem;
  z-index: 2;
}

@mixin multi-line-elipsis($line-height, $max-lines) {
  position: relative;
  max-height: calc(#{$line-height}em * #{$max-lines});
  overflow: hidden;
  padding-right: 1rem;

  &:before {
    position: absolute;
    content: "...";
    bottom: 0;
    right: 0;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: #{$line-height}em;
    background: white;
  }
}

@mixin transition($type: 'primary') {
  @if ($type == 'primary') {
    transition: $transition--primary;
  } @else if($type == 'secondary') {
    transition: $transition--secondary;
  }

  @media (prefers-reduced-motion) {
    transition: none !important;
  }
}
