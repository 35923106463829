.breadcrumb {
  ol {
    li {
      display: inline-block;
      line-height: 20px;

      &:before {
        @extend .fal;
        @extend .fa-chevron-right;
        float: left;
        margin: 0;
        text-indent: 0;
        font-size: 10px;
        margin-top: 4px;
        margin-right: 10px;
        margin-left: 5px;
        line-height: 10px;
      }

      span {
        line-height: 20px;
      }

      &:first-child {
        a {
          display: inline-block;
          overflow: hidden;
          text-indent: -9999px;
          line-height: 20px;


          &:before {
            @extend .fal;
            @extend .fa-home;
            float: left;
            margin: 0;
            margin-top: 5px;
            text-indent: 0;
          }
        }
      }
    }
  }
}