$mobile-menu-position: 'overlay';
$mobile-menu-width: 200px;
$line-height-computed: floor(($font-size-base * $line-height-base));
$headings-small-color: $gray-500;
$component-offset-horizontal: 180px;
$abbr-border-color: $gray-500;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

$grid-gutter-width-base: 40px;
$grid-xs-gutter-width-base: 40px;
$grid-sm-gutter-width-base: 40px;
$grid-md-gutter-width-base: 40px;
$grid-lg-gutter-width-base: 40px;
$grid-xl-gutter-width-base: 40px;

$screen-xs: #{map-get($grid-breakpoints, xs)};
$screen-sm: #{map-get($grid-breakpoints, sm)};
$screen-md: #{map-get($grid-breakpoints, md)};
$screen-lg: #{map-get($grid-breakpoints, lg)};
$screen-xl: #{map-get($grid-breakpoints, xl)};

$screen-phone: $screen-sm;
$screen-tablet: $screen-md;
$screen-desktop: $screen-lg;
$screen-lg-desktop: $screen-xl;

$screen-xs-max: #{map-get($grid-breakpoints, sm) - 1};
$screen-sm-max: #{map-get($grid-breakpoints, md) - 1};
$screen-md-max: #{map-get($grid-breakpoints, lg) - 1};
$screen-lg-max: #{map-get($grid-breakpoints, xl) - 1};

$grid-float-breakpoint: $screen-sm;
$grid-float-breakpoint-min: $screen-sm-max;

// Lazy media query variables.
$mobile:                'screen and (min-width: #{$screen-sm})';
$tablet:                'screen and (min-width: #{$screen-md})';
$normal:                'screen and (min-width: #{$screen-lg})';
$wide:                  'screen and (min-width: #{$screen-xl})';
$mobile-only:           'screen and (max-width: #{$screen-sm-max})';
$tablet-only:           'screen and (min-width: #{$screen-md}) and (max-width: #{$screen-md-max})';
$normal-only:           'screen and (min-width: #{$screen-lg}) and (max-width: #{$screen-lg-max})';
$mobile-down:           'screen and (max-width: #{$screen-sm-max})';
$tablet-down:           'screen and (max-width: #{$screen-md-max})';
$normal-down:           'screen and (max-width: #{$screen-lg-max})';

$grid-gutter-widths: (
  xs: $grid-xs-gutter-width-base,
  sm: $grid-sm-gutter-width-base,
  md: $grid-md-gutter-width-base,
  lg: $grid-lg-gutter-width-base,
  xl: $grid-xl-gutter-width-base
);
