.paragraph {
  margin-bottom: 4rem;

  @media #{$normal} {
    margin-bottom: 8rem;
  }

  h2 {
    margin-bottom: 1rem;
  }
}

.paragraph--type--image-text {

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  @media #{$normal} {
    flex-direction: row;
  }

  &.image-position--right {
    .field--name-field-text {
      order: 1;

      @media #{$normal} {
        margin-right: 4rem;
      }
    }

    .field--name-field-image {
      order: 2;
      margin-top: 10px;

      @media #{$normal} {
        margin-top: 0;
      }
    }
  }

  &.image-position--left {
    .field--name-field-text {
      order: 2;

      @media #{$normal} {
        margin-left: 4rem;
      }
    }

    .field--name-field-image {
      order: 1;
      margin-bottom: 10px;

      @media #{$normal} {
        margin-bottom: 0;
      }
    }
  }

  .field--name-field-text {
    flex: 1 1 auto;

    @media #{$normal} {
      margin-right: 4rem;
    }

    h1,
    h2 {
      color: #333;
    }
  }

  .field--name-field-image {
    width: 100%;

    @media #{$normal} {
      max-width: 45rem;
    }

    img {
      max-width: 100%;
    }
  }
}

blockquote {
  max-width: 760px;
  margin: 0 auto;

  em {
    display: inline-block;
    text-align: center;
    font-weight: 400;
  }
  p {
    text-align: right;
    font-weight: 600;
  }

  p:not(:last-child),
  .p:not(:last-child) {
    margin-bottom: 0;
  }
}