.form-item {
  margin-top: 0;
  margin-bottom: 1rem;

  label {
    width: 50%;
  }
}

.contact-form {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
}