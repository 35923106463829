header.header {
  width: 100%;
  height: $height--nav;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  z-index: 12;
  background-color: rgba(54, 54, 54, 1);
  transition: background-color .2s ease-in-out;

  @media #{$normal} {
    height: $desktop--height--nav;
    padding: 2rem 0;
  }

  .logo {
    text-decoration: none;
    svg {
      width: 4rem;
      height: 4rem;

      fill: #fff;
    }
    span {
      font-size: 2.3rem;
      line-height: 3.6rem;
      color: #fff;
    }
  }

  &.not-filled {
    background-color: rgba(54, 54, 54, 1);

    .has-hero & {
      background-color: rgba(54, 54, 54, 0);
    }

    .page-hero & {

      @media #{$normal} {
        background: transparent;
      }

      @media #{$normal} {
        transform: translate(0, 0);
      }
    }
  }

  &.not-top {
    position: fixed;
  }


  .toolbar-tray-open.toolbar-fixed.user-logged-in & {
    top: 39px;
  }

  .toolbar-tray-open.toolbar-fixed.user-logged-in.toolbar-horizontal & {
    top: 79px;
  }

  .container {
    // width: 100%;
    // max-width: 1200px;
    // height: 100%;
    // margin: 0 auto;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  #block-venturia-branding {
    .block-content-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      .site-name a {
        color: #fff;
        font-size: 36px;
        text-decoration: none;

        img {
          width: auto;
          height: $height--logo;

          @media #{$normal} {
            height: $desktop--height--logo;
          }
        }

        // &:before {
        //   content: "";
        //   width: 185px;
        //   height: 60px;
        //   display: inline-block;
        //   background-image: url(../logo-invert.svg);
        //   background-position: center;
        //   background-size: contain;
        //   background-repeat: no-repeat;
        // }
      }
    }
  }

  .region.region-header,
  .nav--toggle {
    z-index: 10;
  }

  nav.region-navigation {
    transition: all .2s ease;
    display: none;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: 9;

    padding-top: $height--nav;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    @media #{$normal} {
      opacity: 1;
      padding-top: 0;
      justify-content: flex-start;
    }

    .overlay--active & {
      display: flex;
      opacity: 1;
      background-color: rgba(54, 54, 54, 1);

      @media #{$normal} {
        top: auto;
      }
    }

    @media #{$normal} {
      position: relative;
      top: auto;
      bottom: auto;
      display: block;

      flex: 1 0 0;

      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .nav-icon {
    display: flex;
    z-index: 20;

    .icon--label--open,
    .icon--label--close {
      @include font--primary();
      font-size: 1.6rem;
      color: white;
      display: none;
      padding-right: .7rem;
      line-height: 3.1rem;
      font-weight: 600;
      letter-spacing: .3px;
      cursor: pointer;
    }

    .icon--label--open {
      display: inline-block;
    }

    @media #{$normal} {
      display: none;
    }

    i {
      font-size: 3rem;

      &:before {
        background-position-y: -2em;
      }
    }
  }

  .nav--toggle--open {

    // Change the icon to close button
    .icon--label--close {
      display: inline-block;
    }

    .icon--label--open {
      display: none;
    }
  }


}