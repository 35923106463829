body {
  @include font-size($font-size--body);
  @include font--primary();
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $color--text;
  line-height: $line-height--body;

  @media #{$normal} {
    @include font-size($desktop--font-size--body);
    line-height: $desktop--line-height--body;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin: 0;
  color: inherit;

  a {
    text-decoration: none;
    color: $color--black;

    &:hover {
      text-decoration: none;
    }
  }

  &.text-large {
    @include text-large('include-text-style');
  }

  &.text-small {
    @include text-small('include-text-style');
  }
}

h1,
.h1 {
  @include font-size($font-size--h1);
  @include font--secondary();
  line-height: $line-height--h1;
  margin-bottom: map-get($spacings, 5);

  @media #{$normal} {
    @include font-size($desktop--font-size--h1);
    line-height: $desktop--line-height--h1;
    margin-bottom: map-get($desktop--spacings, 6);
  }
}

h1.small,
.h1--small {
  @media #{$normal} {
    @include font-size($desktop--font-size--h1--small);
    line-height: $desktop--line-height--h1--small;

    &:after {
      margin-left: 0;
    }
  }
}

h2,
.h2 {
  @include font-size($font-size--h2);
  @include font--secondary();
  line-height: $line-height--h2;
  margin-bottom: map-get($spacings, 4);

  @media #{$normal} {
    @include font-size($desktop--font-size--h2);
    line-height: $desktop--line-height--h2;
    margin-bottom: map-get($desktop--spacings, 5);
  }
}

h3,
.h3 {
  @include font-size($font-size--h3);
  @include font--secondary();
  line-height: $line-height--h3;
  margin-bottom: 1.2rem;
  margin-bottom: map-get($spacings, 4);

  @media #{$normal} {
    @include font-size($desktop--font-size--h3);
    line-height: $desktop--line-height--h3;
    margin-bottom: map-get($desktop--spacings, 5);
  }
}

h4,
.h4 {
  @include font-size($font-size--h4);
  @include font--secondary();
  line-height: $line-height--h4;
  margin-bottom: map-get($spacings, 2);

  @media #{$normal} {
    @include font-size($desktop--font-size--h4);
    line-height: $desktop--line-height--h4;
    margin-bottom: map-get($desktop--spacings, 4);
  }
}

h5,
.h5 {
  @include font-size($font-size--h5);
  @include font--secondary();
  line-height: $line-height--h5;
  margin-bottom: map-get($spacings, 2);

  &:first-letter {
    text-transform: unset;
  }

  @media #{$normal} {
    @include font-size($desktop--font-size--h5);
    line-height: $desktop--line-height--h5;
    margin-bottom: map-get($desktop--spacings, 1);
  }
}

p,
.p {
  margin-bottom: 0;
}

p:not(:last-child),
.p:not(:last-child),
ul:not(:last-child) {
  margin-bottom: map-get($spacings, 5);

  @media #{$normal} {
    margin-bottom: map-get($desktop--spacings, 6);
  }

  &.text-small,
  .text-small & {
    @media #{$normal} {
      margin-bottom: map-get($desktop--spacings, 4);
    }
  }
}

a {
  color: $color--text;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $color--black;
    text-decoration: none;
  }
}

.text-large {
  @include text-large();
}

.text-small {
  @include text-small();
}

.text-bold {
  font-weight: 500;
  color: $color--black;
}

.text-muted {
  color: $color--disabled;
}
