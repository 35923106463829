.region-navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: stretch;
  align-items: center;

  ul.menu {
    margin: 0 1.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    // justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    
    margin-bottom: $height--nav;

    @media #{$normal} {
      margin-bottom: 0;
      flex-direction: row;
    }

    li {
      margin: 1rem 1.5rem;
      padding: 0;

      @media #{$normal} {
        margin: 0 1.5rem;
      }

      a:not(.button) {
        color: #fff;
        text-decoration: none;

        &:after {
          content: "";
          width: 100%;
          height: 2px;
          display: block;
          opacity: 0;
          background-color: $color--white;
          transition: opacity .2s ease-in-out;
        }

        &:hover,
        &.is-active {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .menu--account {
    ul.menu {
      margin: 0;
    }
  }
}