*,
*:before,
*:after {
  box-sizing: border-box;
}

.hidden {
  display: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  overflow-y: scroll;
  font-size: 62.5%;

  @media print {
    font-size: 50%;
  }

  // Disable scrolling.
  &.js-menu-open,
  &.js-disable-body-scrolling {
    overflow: hidden;
    position: relative;
  }
}

.hidden {
  display: none;
}

body {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; // No horizontal scrolling
  -webkit-print-color-adjust: exact !important;

  // Disable scrolling.
  &.js-menu-open,
  &.js-disable-body-scrolling {
    overflow: hidden;
    position: relative;
  }

  // To read browser widths from js.
  &:before {
    display: none;
    content: 'mobile';

    @media #{$tablet} {
      content: 'tablet';
    }

    @media #{$normal} {
      content: 'desktop';
    }
  }
}

.dialog-off-canvas-main-canvas {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
  user-select: none;
}

svg {
  vertical-align: middle;
}

table {
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.layout-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-top: 8rem;

  @media #{$normal} {
    padding-top: 12rem;
  }

  &.has-hero {
    padding-top: 0;

    @media #{$normal} {
      padding-top: 0;
    }
  }

  @media print {
    padding-top: 0 !important;
  }
}

main.main-container {
  flex: 1 0 auto;
}

.more-link {
  margin-top: map-get($spacings, 7);

  @media #{$normal} {
    margin-top: map-get($desktop--spacings, 7);
  }
}

.pager {
  padding: 0;
  margin: 0;

  li {
    display: block;
    padding: 0;
  }
}