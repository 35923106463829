// -----------------------------------------------------------------------------
//
// Grid utilities
//
// -----------------------------------------------------------------------------

$grid-xs-gutter-width-base: 40px;
$grid-sm-gutter-width-base: 40px;
$grid-md-gutter-width-base: 40px;
$grid-lg-gutter-width-base: 40px;
$grid-xl-gutter-width-base: 40px;

// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

// Primary fonts will be used for 90% of the text styles, mainly body text.
// Secondary fonts will be used mainly for headings.
$font--primary: 'Nunito', sans-serif;
$font--secondary: $font--primary;

// MOBILE: Font sizes (in pixels) / line-heights
$font-size--body: 16;
$line-height--body: 22px;

$font-size--h1: 40;
$line-height--h1: 1.13;

$font-size--h2: 30;
$line-height--h2: 1.13;

$font-size--h3: 20;
$line-height--h3: 1.17;

$font-size--h4: 25;
$line-height--h4: 1.2;

$font-size--h5: 18;
$line-height--h5: 1.56;

// DESKTOP: Font sizes (in pixels) / line-heights
$desktop--font-size--body: 18;
$desktop--line-height--body: 1.89;

$desktop--font-size--body--large: 22;
$desktop--line-height--body--large: 1.91;

$desktop--font-size--body--small: 16;
$desktop--line-height--body--small: 1;

$desktop--font-size--h1: 65;
$desktop--line-height--h1: 1;

$desktop--font-size--h1--small: 55;
$desktop--line-height--h1--small: 1.18;

$desktop--font-size--h2: 45;
$desktop--line-height--h2: 1.33;

$desktop--font-size--h3: 30;
$desktop--line-height--h3: 1.17;

$desktop--font-size--h4: 25;
$desktop--line-height--h4: 1.2;

$desktop--font-size--h5: 20;
$desktop--line-height--h5: 1.5;

// -----------------------------------------------------------------------------
//
// Colors
//
// -----------------------------------------------------------------------------
// https://encycolorpedia.nl/293749
$color--primary: #293749;
$color--primary--light: #33455b;

$color--text: #746366;
$color--text--lighter: lighten($color--text, 40%);

$color--black: #1d1d1b;
$color--white: #fff;

$color--grey: #ccc;
$color--grey--lighter: #ddd;
$color--grey--lightest: #f7f7f7;

$color--disabled: #b9b9b9;

$color--error: #ffe5e9;
$color--warn: #ffbb4a;
$color--valid: #00d870;

// -----------------------------------------------------------------------------
//
// Sizes
//
// -----------------------------------------------------------------------------

// MOBILE
$height--button: 55px;
$width--button: 100%;
$padding--button: 0 60px 0 20px;
$padding--button-center: 0 20px;

$height--nav: 7rem;
$height--logo: 5rem;
$height--hero: 45rem;

// DESKTOP
$desktop--height--button: 60px;
$desktop--width--button: 200px;
$desktop--padding--button: 0 80px 0 30px;
$desktop--padding--button-center: 0 30px;

$desktop--height--nav: 8rem;
$desktop--height--logo: 6rem;
$desktop--height--hero: 60rem;

// -----------------------------------------------------------------------------
//
// Borders
//
// -----------------------------------------------------------------------------

$border-color--primary: $color--grey;
$border-radius--primary: 5px;

// -----------------------------------------------------------------------------
//
// Shadows
//
// -----------------------------------------------------------------------------

$box-shadow--primary: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
$box-shadow--secondary: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
$box-shadow--button--hover: $box-shadow--primary;
$box-shadow--button--active: 0 3px 5px 0 rgba(0, 0, 0, 0.15);

// -----------------------------------------------------------------------------
//
// Animations
//
// -----------------------------------------------------------------------------

$transition--primary: 0.3s;
$transition--secondary: 0.4s;
$transition--button: $transition--primary;

// -----------------------------------------------------------------------------
//
// z-index
//
// -----------------------------------------------------------------------------

$z-index-0: 0;
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 100;
$z-index-5: 1000;
$z-index-6: 10000;

// -----------------------------------------------------------------------------
//
// Paddings
//
// -----------------------------------------------------------------------------

$spacings: (
  0: 0,
  1: 5px,
  2: 10px,
  3: 15px,
  4: 20px,
  5: 20px,
  6: 30px,
  7: 30px,
  8: 30px,
  9: 30px,
  10: 50px,
);

$desktop--spacings: (
  0: 0,
  1: 5px,
  2: 10px,
  3: 15px,
  4: 20px,
  5: 30px,
  6: 40px,
  7: 70px,
  8: 80px,
  9: 100px,
  10: 120px,
);
