footer {
  padding: 4rem 0;
  background-color: #363636;

  color: #fff;

  @media #{$normal} {
    padding: 7rem 0;
  }

  .region-footer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: flex-start;

    @media #{$normal} {
      flex-direction: row;
    }
  }

  nav, section {
    width: 100%;
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$normal} {
      min-width: 280px;
      margin-bottom: 0rem;
    }
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: .5rem;
  }

  ul.menu {
    margin: 0;
    padding: 0;

    a {
      font-size: 1.6rem;
      text-decoration: none;
      letter-spacing: .5px;
      color: #fff;

      &.is-active,
      &:hover {
        color: rgba(#fff, .7);
      }
    }
  }

  .region-copyright {
    margin-top: 5rem;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: flex-start;

    border-top: 1px solid rgba(#fff, .5);

    @media #{$normal} {
      margin-top: 4rem;
      padding-top: 2rem;
      flex-direction: row;
    }

    nav {
      width: auto;

      li {
        width: 100%;
        display: block;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        @media #{$normal} {
          width: auto;
          display: inline-block;
          margin-right: 3rem;
          margin-bottom: 0;

          &:last-child {
            margin-right: 0;
          }
        }

        a {
          color: rgba(#fff, .5);

          &.is-active,
          &:hover {
            color: rgba(#fff, .3);
          }
        }
      }
    }

    p {
      font-size: 1.6rem;
      text-decoration: none;
      letter-spacing: .5px;
      color: #fff;
      line-height: 34px;
    }
  }
}